<template>
  <div>
    <section style="background-color: white" class="home-hero">
      <v-container>
        <v-row style="min-height: 80vh" justify="center" align="center">
          <v-col cols="12" md="6">
            <v-container>
              <div class="pt-6">
                <h1>
                  <span
                    class="text-md-h1 text-sm-h3 d-none d-sm-block red--text lighten-1 font-weight-bold white--text text-left mb-2"  style="text-shadow: 1px 1px 2px #000000"
                  >
                    CONCRAFT</span
                  >
                <div
                  class="display-1 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>Premium Scaffolding Equipment</span>
                </div>
                <div
                  class="display-1 font-weight-black white--text text-left mb-3"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>and Scaffolding Services</span>
                  
                </div>
              </h1>
              <h2>
                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >Full-feature scaffolding enterprise that puts safety and
                    sturdiness first</span
                  >
                </div>
              </h2>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
              max-width="90vw"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/HOMEPART.webp?alt=media&token=8cde65d7-ac9c-4abf-9015-2f437eea6c10"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row class="my-8" justify="center" align="center">
          <v-col cols="12" md="6">
            <v-img
              class="mx-auto"
              max-height="296px"
              max-width="320px"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/image17.png?alt=media&token=0be6db91-7114-4cbf-83d1-88ec3c81fee8"
            ></v-img>
          </v-col>
          <v-col class="text-left white--text" cols="12" md="6" lg="5">
            <h1
              style="text-shadow: 1px 1px 2px #000000"
              class="display-2 red--text lighten-1 font-weight-bold"
            >
              About Concraft
            </h1>
            <v-divider
              style="max-width: 420px"
              class="mt-4 mb-6 white"
            ></v-divider>
            <p class="body-1">
              Concraft lies in Johannesburg and believes in the power of
              full-service provided scaffolding
            </p>
            <p class="body-1">
              Concraft strives for only the best in service regarding
              scaffolding and the safe erecting of said scaffolding
            </p>
            <p class="body-1">
              Concraft goes through thorough testing and prepartion to prepare
              each and every employee of the company not only giving the client
              peace of mind but giving the employee the confidence necessary to
              work at heights by knowing they are knowledgeable about their
              field
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="secondary">
      <v-container>
        <v-row
          class="mt-0 pt-0 mb-8"
          justify="center"
          align="center"
        >
          <v-col cols="12" class="mb-8"
            ><h1 class="display-1 text-xs-center mt-6" style="color: #d6ebff">
              Our
              <span
                class="font-weight-black red--text lighten-1 display-2"
                style="font-weight: 1200; text-shadow: 1px 1px 2px #000000"
                >Mission
              </span>
            </h1>
          </v-col>
          <v-col class="ma-0 pa-0 white--text " cols="12" md="6">
            <ul class="mx-2">
              <li class="body-1 ">
                To operate in a way that is environmentally sustainable and
                utilize our natural resources in a responsible manner
              </li>
              <li class="body-1 mt-2">
                To follow through the needs of the customer and ensure that they
                are satisfied with our quality and services at all times
              </li>
              <li class="body-1 mt-2">
                To manage sustained and comprehensive quality control
              </li>
              <li class="body-1 mt-2">
                To abide to the highest health and safety standards and protect
                our employees, stakeholders and members of public
              </li>
            </ul>
          </v-col>
          <v-col class="ma-0 pa-0 white--text" cols="12" md="6">
            <ul class="mx-2">
              <li class="body-1">
                To view each contract as a mutually beneficial long-term
                relationship that is based upon trust, quality service and
                mutual understanding
              </li>
              <li class="body-1 mt-2">
                To constantly strive to develop new systems to ensure an easier,
                safer and more effective access scaffolding solution
              </li>
              <li class="body-1 mt-2">
                To provide top-quality scaffolding service that exceeds client
                expectations of safety, price, service and experience
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1543412970-5673add7e407?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
</style>
