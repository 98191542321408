<template>
  <section>
    <v-container style="min-height: 65vh">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-flex class="display-1 text-xs-center my-5" style="color: #d6ebff">
            Our
            <span
              class="font-weight-black red--text lighten-1 display-2"
              style="font-weight: 1200; text-shadow: 1px 1px 2px #000000;"
            >
              Premium
            </span>
            Services
          </v-flex>
          <v-row class="mx-auto">
            <v-col v-for="card in cards" :key="card.id" cols="12" md="6" lg="4">
              <v-card class="text-left d-flex flex-column">
                <v-img eager max-height="180px" :src="card.image"></v-img>
                <v-card-title
                  class="headline mt-3 font-weight-bold blue--text lighten-1"
                  style="text-shadow: 1px 1px 1px #000000 ; word-break: normal;"
                >
                  {{ card.title }}
                </v-card-title>
                <v-card-text class="subheading mt-3">{{ card.description }}</v-card-text>
                <v-spacer />
                <v-card-actions min-height="100%">
                  <v-btn :to="card.link" dark elevation="0" color="#008FE8">Read More</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-img class="d-none d-md-block" src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/black.webp?alt=media&token=34456b33-a2ea-4e76-8a5f-3574ddf33fd6"></v-img>
    <v-img class="d-md-none pt-8" src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/black.webp?alt=media&token=34456b33-a2ea-4e76-8a5f-3574ddf33fd6"></v-img>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          title: "Premium Scaffolding Equipment",
          description:
            "Premium scaffolding equipment for hire to the client at affordable prices. We source our scaffolding from either manufacturers or from older scaffolding companies where we take their used scaffolding and refurbish them, thereby recycling old materials and providing a product that has been refurbished and is certified to be work and construction ready. Concraft only pushes quality in the form of their scaffolding.",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g2407.webp?alt=media&token=e5977898-0274-42a4-9eac-ddef0e41b668",
          link: "/about"
        },
        {
          id: 2,
          title: "Scaffolding Services & Labour",
          description:
            "Concraft sources only the most qualified and most experienced labour one can find on the market at the moment. We go through all the important safety checks when screening our labourers and make sure they are fully qualified and certified from inception. That is the Concraft Guarantee",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/scaffolinghero2.webp?alt=media&token=3e43ea35-c203-4d5b-81a9-1737d9596b36",
          link: "/services"
        },
        {
          id: 3,
          title: "Scaffolding Placing Assessments",
          description:
            "We offer scaffolding assessments and scaffolding placing assessments free of charge to the customer. This process is usually out-sourced along with labour as the process can take long for an individual company to take on with each new project or endeavour they want to pursue.",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/scaffolinghero3.webp?alt=media&token=38b59658-ba4e-4742-ae59-5c3ab4104c88",
          link: "/services"
        },
        {
          id: 4,
          title: "Engineering Services",
          description:
            "From the ground up – we design, fabricate, and erect steel structures, provide on-site civil work, and offer a full spectrum of engineering and maintenance services.",
          image:
            require("@/assets/engineering.webp"),
          link: "/capabilities"
        },
        {
          id: 5,
          title: "Equipment Supply",
          description:
            "Quality equipment for top-notch finishes – offering everything from spray painting booths to climate control units, ensuring your project is equipped for excellence.",
          image:
            require("@/assets/equipment.webp"),
          link: "/capabilities"
        },
        {
          id: 6,
          title: "Consulting Services",
          description:
            "Guiding your projects to success – we provide consultancy in facility design, application processes, and training, with a focus on efficiency and safety.",
          image:
            require("@/assets/consulting.webp"),
          link: "/capabilities"
        },
        {
          id: 7,
          title: "Welding & Other Services",
          description:
            "Our expert welding and boiler-making services ensure durable constructions. Protect and enhance your assets with our corrosion control, industrial painting, and specialized insulation services.",
          image:
            require("@/assets/welding.webp"),
          link: "/capabilities"
        }
      ]
    };
  }
};
</script>

<style>
.position {
  z-index: -1;
  bottom: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/black.webp?alt=media&token=34456b33-a2ea-4e76-8a5f-3574ddf33fd6");
  background-size: contain;
}

/* Center the cards */
.v-row.align-center {
  justify-content: center;
}
</style>
