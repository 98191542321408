<template>
  <div>
    <v-section>
      <v-container class="mb-8" >
        <!-- Desktop -->
        <v-row  style="min-height: 40vh" justify="center" align="center">
          <v-col cols="12" md="9">
            <div
              class="display-2 font-weight-black white--text text-xs-center mb-md-8 mt-16"
            >
              <span class="red--text lighten-1" style="text-shadow: 1px 1px 2px #000000"
                >Market leaders we have worked with</span
              >
            </div>
            <v-sheet
              dark
              class="grey darken-3"
              style="border-radius: 8px"
              elevation="0"
              max-width="100vw"
            >
              <v-slide-group
                v-model="model"
                style=""
                active-class="success"
                show-arrows
              >
                <v-slide-item v-for="n in preExperience" :key="n">
                  <v-card
                    style="background-color: white"
                    class="ma-4"
                    height="200"
                    width="200"
                  >
                    <v-row class="fill-height" align="center" justify="center">
                      <v-img
                        eager
                        max-height="100%"
                        max-width="80%"
                        contain
                        :src="n.link"
                      >
                      </v-img>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-container  >
        <v-row style="min-height: 60vh" justify="center" align="center" class="flex-md-row-reverse mt-8">
          <v-col cols="12" md="6" lg="5">
            <v-container>
              <div>
                <div class="display-2 font-weight-black white--text text-left">
                  <span
                    style="
                      text-shadow: 1.2px 1.2px 1.75px #001529;
                    "
                    class="red--text lighten-1"
                    >Our Mission Statement</span
                  >
                </div>
                <!-- <div class="display-3 font-weight-black white--text text-center mb-3">
          <span style="color: #d6ebff; text-shadow: 1.2px 1.2px 1.75px #001529"
            >and Scaffolding Services</span
          >
        </div> -->
                <div class="display-1 grey--text lighten-1 text-left">
                  <span 
                    >To secure you at height with a complete scaffolding
                    solution</span
                  >
                </div>
                <div class="headline white--text text-left">
                  <span style="color: #f1faee"
                    >Provided with certified and accredited labour</span
                  >
                </div>
                <v-btn color="#d6ebff" class="mt-8">Learn More</v-btn>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
            contain
            class="mx-auto"
            style="border-radius:8px"
              eager
              max-width="420px"
              src="@/assets/missionstatement.png"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preExperience: [
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2FDRD.png?alt=media&token=72a8bac7-d39a-4c49-adb8-03018eeee52b",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2FSibanye-Stillwater.svg.png?alt=media&token=1ff98f42-22b9-44cb-ba29-525ea9e813a5",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2Fanglo_gold_ashanti.png?alt=media&token=b35d9ed8-1356-486c-8d1d-87a0b00d566c",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2Fconsol.jpg?alt=media&token=c2c5c2bd-5613-4b99-945d-0fada01b5871",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2Fgold-harmony-logo.png?alt=media&token=9b4e2d8b-0105-49f2-8675-45b9e96918bd",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2Fkimberly_clark_rgb_blue_logo.jpg?alt=media&token=2d85e109-bd66-40fc-9112-f86f1b387163",
        },
        {
          link: "https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/previousbrands%2Flonmin.png?alt=media&token=25c15650-59b7-4dd9-a2ce-1346f033a303",
        },
      ],
      model: null,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
};
</script>

<style>
.grad {
  background-color: #080808;
  opacity: 0.8;
}
.positionleft {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 1440px;
  height: 30vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/path7347.webp?alt=media&token=e8bace56-6e89-4927-a8b4-09ec24482179");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
