<template>
  <v-app style="background-color: #1f1f1f">
    <v-overlay opacity="1" v-model="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        v-model="isLoading"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-app-bar dark class="px-md-16" height="72px" app>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            to=""
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/logoCS.webp?alt=media&token=dc3bed41-3ea7-47fb-af68-be113620c778"
            transition="scale-transition"
            width="60"
          />
        </router-link>

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down pa-1"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/logowordCS1.webp?alt=media&token=54a6489f-7f84-41f7-a5d4-ff00d1d8a0ff"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu
          class="mr-2 "
          open-on-hover
          transition="fade-transition"
          offset-y
          close-on-click
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 d-none d-md-block " text v-bind="attrs" v-on="on">
              <span class="mr-2 font-weight-black">Menu</span>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list dark>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-btn class="text-left" dark text :to="item.url">{{
                item.title
              }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          class="mr-2 hidden-md-and-down"
          open-on-hover
          transition="fade-transition"
          offset-y
          close-on-click
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark class="mr-2 d-md-none" text v-bind="attrs" v-on="on">
              <span class="mr-2 font-weight-black">Menu</span>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list dark>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-btn class="text-left" dark text :to="item.url">{{
                item.title
              }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn to="/contactus" large style="color: white" color="#C00102 ">
        <span>Contact Us!</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-scroll-y-reverse-transition mode="out-in">
        <router-view />
      </v-scroll-y-reverse-transition>
    </v-main>
    <v-footer
      min-height="10vh"
      class="pt-0 mt-0"
      dark
      style="background-color: #1f1f1f"
    >
      <v-row>
        <v-col class="px-md-16 pt-8" md="4" cols="auto">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/logoCS.webp?alt=media&token=dc3bed41-3ea7-47fb-af68-be113620c778"
            transition="scale-transition"
            width="75"
          />
          <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down pa-1"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/logowordCS1.webp?alt=media&token=54a6489f-7f84-41f7-a5d4-ff00d1d8a0ff"
          width="140"
        />
        </v-col>
        <v-col cols="auto" md="4">
          <v-card color="transparent" elevation="0">
            <v-card-text>
              <router-link to="/about">
                <v-btn text style="font-weight: 500" class="mb-1">About</v-btn></router-link
              ><br />
              <router-link to="/services">
                <v-btn text style="font-weight: 500" class="mb-1"
                  >Services</v-btn
                ></router-link
              ><br />
              <router-link to="/team">
                <v-btn text  style="font-weight: 500" class="mb-1"
                  >Team</v-btn
                ></router-link
              ><br />
              <router-link to="/gallery">
                <v-btn text  style="font-weight: 500" class="mb-1"
                  >Gallery</v-btn
                ></router-link
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="auto" md="4" class="px-8">
          <v-card class="my-4" elevation="0" color="transparent">
            Join our newsletter to stay in the loop
            <v-row class="my-4">
              <v-text-field
                hint="john@doe.com"
                class="my-0"
                style="margin: 0 -4"
                outlined
                label="Enter your email"
              ></v-text-field>
              <v-btn class="mx-2 my-2" dark color="green">Join now</v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>


export default {
  name: "App",
  metaInfo: {
    title: 'Concraft Scaffolding', // You can adjust the title if needed
    meta: [
      {
        name: 'description',
        content: "Concraft Scaffolding: Johannesburg's premier scaffolding enterprise emphasizing safety and sturdiness. Offering top-quality scaffolding equipment, certified labor, and free assessments. Committed to environmental sustainability, customer satisfaction, and the highest health and safety standards."
      }
    ]
  },

  data: () => ({
    expand: false,
    isLoading: true,
    items: [
      { title: "About Us", url: "/about" },
      { title: "Our Services", url: "/services" },
      { title: "Our Products", url: "/products" },
      { title: "Our Capabilities", url: "/capabilities" },
      { title: "Our Team", url: "/team" },
      { title: "Gallery", url: "/gallery" },
      
    ],
    //
  }),
  mounted() {
    // console.log(this.$store.state.userProfile)
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
    // Code that will run only after the
    // entire view has been rendered
  },
};
</script>
<style>
.application {
  font-family: 'Russo One', sans-serif;
        src: url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap') format("TTF"),
  
}
</style>
