<template>
  <div>
    <HomeHero />
    <HomeDetails />
    <HomeRefs />
  </div>
</template>

<script>
import HomeHero from "@/components/HomeHero";
import HomeDetails from "@/components/HomeDetails";
import HomeRefs from "@/components/HeroRefs";

export default {
  name: "Home",
  metaInfo: {
    title: "Concraft Scaffolding - Home",
    meta: [
      {
        name: "description",
        content:
          "Welcome to Concraft Scaffolding, your trusted partner for all scaffolding needs in Johannesburg.",
      },
    ],
  },

  components: {
    HomeHero,
    HomeDetails,
    HomeRefs,
  },
};
</script>
